import React from "react";
import styled from "styled-components/macro";
import { Discord } from 'styled-icons/boxicons-logos'
import Modal from './modal'




const DiscordIcon = styled(Discord)`
    color: white;
    width: 100px;
    min-height: 100px;
`;

const DiscordName = styled.h1`
  color: white;
`;

const DiscordNumber = styled.h2`
  color: grey;
`;

export default ({ showDiscordModal, toggleDiscordModal }) => (
    showDiscordModal ?
        <Modal Icon={ DiscordIcon } toggleDisplay={ toggleDiscordModal }>
            <DiscordName>
                dbgregory
            </DiscordName>
            <DiscordNumber>
                #7077
            </DiscordNumber>
        </Modal>
        :
        <></>
);

