import React, { useEffect, useRef } from 'react';
import styled from "styled-components/macro";
import { CloseButton, PageDim } from "../components";
import global from "../static";




const CloseRow = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5px;
  align-items: center;
  border-radius: ${ global.styles.BORDER_RADIUS };
  width: 300px;
  height: 300px;
  background: rgb(${ global.colors.PRIMARY_RGB });
  z-index: 1;
`;

const ModalBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default ({ children, toggleDisplay, Icon }) => {

    const modalRef = useRef(null);


    useEffect(() => {
        const handleOutsideClick = event => {
            if (modalRef.current && !modalRef.current.contains(event.target)) toggleDisplay();
        };
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [toggleDisplay]);

    return (
        <PageDim>
            <ModalContainer ref={ modalRef }>
                <CloseRow>
                    <CloseButton onClick={ () => toggleDisplay() }/>
                </CloseRow>
                { Icon ? <Icon/> : undefined }
                <ModalBody>
                    { children }
                </ModalBody>
            </ModalContainer>
        </PageDim>
    )
};
