import React, { useState } from "react";
import styled from "styled-components/macro";
import { Card } from '../components';




const ScrollContainer = styled.div`
    width: 100vw;
    height: min-content;
    box-sizing: border-box;
    padding: 10px;
    overflow: auto;
    display: flex;
    flex-direction: row;
    ::-webkit-scrollbar {
      display: none;
    }
`;

const hoverStyle = (hovered, idx) => {
    if (hovered === -1) {
        return {
            style: {
                margin: `5px`
            }
        }
    }
    if (hovered === idx) {
        return {
            style: {
                margin: `5px`,
                transform: `scale(1.05)`
            }
        }
    } else if (hovered >= idx) {
        return {
            style: {
                margin: `5px`,
                transform: `translateX(-1em)`
            }
        }
    } else if (hovered <= idx) {
        return {
            style: {
                margin: `5px`,
                transform: `translateX(1em)`
            }
        }
    }
};


const populateCard = (num, hovered, setHovered, Component) => {
    const cards = [];
    for (let i = 0; i < num; i++) {
        cards.push(
            <Component
                { ...hoverStyle(hovered, i) }
                onMouseEnter={ () => setHovered(i) }
                onMouseLeave={ () => setHovered(-1) }
            />
        )
    }
    return cards;
};


export default () => {
    const [hovered, setHovered] = useState(-1);

    return (
        <ScrollContainer>
            { populateCard(10, hovered, setHovered, Card) }
        </ScrollContainer>
    )
}