import ContactModal from '../../modals/modal.contact'
import { connect } from 'react-redux';
import { toggleContactModal } from "../reducers/ui/actions";

// Props that the component will receive from Redux, treat as normal props within component
const mapStateToProps = (state) => ({
    showContactModal: state.ui.showContactModal
});

// dispatch functions are sent as an object, replaces mapDispatchToProps
export default connect(mapStateToProps, { toggleContactModal })(ContactModal);
