import React from "react";
import styled from "styled-components/macro";
import { Mail } from 'styled-icons/feather';
import { Location } from 'styled-icons/evil'
import Modal from './modal'




const ModalRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: min-content;
`;

const iconStyle = `
  color: white;
  height: 32px;
  min-height: 32px;
  margin: 10px;
`;

const MailIcon = styled(Mail)`
  ${ iconStyle }
`;

const LocationIcon = styled(Location)`
  ${ iconStyle }
`;

const ModalText = styled.span`
  color: white;
  font-size: 1.3em;
`;

const ModalTitle = styled.h1`
  color: white;
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  box-sizing: border-box;
  padding: 5px 0 35px 0;
`;

export default ({ showContactModal, toggleContactModal }) => {


    return (
        showContactModal ?
            <Modal toggleDisplay={ toggleContactModal }>
                <ModalContent>
                    <ModalRow>
                        <ModalTitle>Denver Gregory</ModalTitle>
                    </ModalRow>
                    <ModalRow>
                        <LocationIcon/>
                        <ModalText>San Diego, CA</ModalText>
                    </ModalRow>
                    <ModalRow>
                        <MailIcon/>
                        <ModalText>DenverBGregory@Gmail.com</ModalText>
                    </ModalRow>
                </ModalContent>
            </Modal>
            :
            <></>
    )
};

