import React from 'react';
import styled from "styled-components/macro";
import global from '../static';




const CardContainer = styled.div`
    width: ${ p => p.width ? p.width + 'px' : '350px' };
    height: ${ p => p.height ? p.height + 'px' : '350px' };;
    min-width: ${ p => p.width ? p.width + 'px' : '350px' };
    min-height: ${ p => p.height ? p.height + 'px' : '350px' };
    background: rgba(${ global.colors.PRIMARY_RGB },.9);
    border-radius: ${ global.styles.BORDER_RADIUS };
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 15px;
    transition: all ${ global.transitions.MAIN_TRANSITION };
    &:hover{
      cursor: pointer;
    }
`;

export default (props = {}, { children }) => {
    return (
        <CardContainer { ...props }>
            { children }
        </CardContainer>
    )
};