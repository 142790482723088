const initialState = {
    showDiscordModal: false,
    showContactModal: false
};


export default function ui(state = initialState, action) {
    switch (action.type) {
        case "TOGGLE_DISCORD_MODAL":
            return {
                ...state,
                showDiscordModal: !state.showDiscordModal
            };
        case "TOGGLE_CONTACT_MODAL":
            return {
                ...state,
                showContactModal: !state.showContactModal
            };
        default:
            return state;
    }
}
