import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from 'redux-thunk';
import ui from './reducers/ui'

// rootReducer combines all of the separate reducers and maps them to whatever key you desire
const rootReducer = combineReducers(
    {
        ui: ui
    }
);

// configure store connects the Thunks and Redux to be used as reducer actions and
// creates a single reducer from the combined reducers function
export default function configureStore() {
    // Array of any middleware to be used by Redux
    const middleware = [thunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middleware);
    return createStore(rootReducer, middlewareEnhancer);
}
