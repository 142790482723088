import React from "react";
import styled from "styled-components/macro";
import global from '../static'
import {
    AdobeIcon, AwsIcon,
    CodepenIcon,
    CssIcon, DeploymentIcon,
    DesignIcon,
    DevelopmentIcon, GoogleIcon, HerokuIcon,
    HtmlIcon, MicrosoftIcon, MongoIcon, MysqlIcon, NetlifyIcon, NodeIcon, NpmIcon, PlusIcon,
    ReactIcon, ReduxIcon,
    SassIcon
} from "../components/icons";




const DescriptionHero = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  box-sizing: border-box;
  padding: 0 25px;
`;

const HeroBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 200px;
  background: rgba(${ global.colors.PRIMARY_RGB },.85);
  border-radius: ${ global.styles.BORDER_RADIUS };
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const HeroColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  padding: 25px;
  width: 100%;
  min-height: 100%;
  color: white;
  border-right: ${ props => props.border ? '#DFDBE5 1px solid' : 'none' }};
  @media (max-width: ${ global.sizes.TABLET }) {
    border-right: none;
    border-bottom: ${ props => props.border ? '#DFDBE5 1px solid' : 'none' };
  }
`;

const ColumnTitle = styled.h2`
  color: white;
  text-align: center;
  font-size: 2em;
  margin: 0 0 56px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;


export default () => {
    return (
        <DescriptionHero>
            <HeroBody>
                <HeroColumn border>
                    <DesignIcon/>
                    <ColumnTitle>Design</ColumnTitle>
                    <AdobeIcon style={ { color: '#ff0000', boxSizing: 'border-box', padding: '5px' } }/>
                    <CodepenIcon/>
                    <HtmlIcon/>
                    <CssIcon/>
                    <SassIcon style={ { color: '#CD6799' } }/>
                </HeroColumn>
                <HeroColumn border>
                    <DevelopmentIcon/>
                    <ColumnTitle>Development</ColumnTitle>
                    <RowContainer style={ { maxWidth: '220px' } }>
                        <ReactIcon style={ { color: '#61DBFB' } }/>
                        <PlusIcon/>
                        <ReduxIcon style={ { color: '#764abc' } }/>
                    </ RowContainer>
                    <NodeIcon style={ { color: '#68A063' } }/>
                    <MongoIcon style={ { maxWidth: '200px' } }/>
                    <MysqlIcon style={ { minHeight: "90px" } }/>
                    <NpmIcon style={ { color: '#CC3534' } }/>
                </HeroColumn>
                <HeroColumn>
                    <DeploymentIcon/>
                    <ColumnTitle>Deployment</ColumnTitle>
                    <GoogleIcon style={ { boxSizing: 'border-box', padding: '5px' } }/>
                    <AwsIcon/>
                    <MicrosoftIcon/>
                    <HerokuIcon/>
                    <NetlifyIcon/>
                </HeroColumn>
            </HeroBody>
        </DescriptionHero>
    );
};
