import React from 'react'
import styled from 'styled-components/macro'
import { ContactModal, DiscordModal, NavBar } from "../store/containers";
import { ScrollHero } from '../modules';




const MainBodyContainer = styled.div`\
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;


export default () => (
    <MainBodyContainer>
        <DiscordModal/>
        <ContactModal/>
        <NavBar/>
        <ScrollHero/>
    </MainBodyContainer>
);

