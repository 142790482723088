import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Close } from "styled-icons/evil"
import global from '../static'




export const UnstyledButton = styled.button`
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 0;
    background: transparent;
    :hover,:focus{
      background: transparent;
      outline: none;
    }
`;


export const UnstyledLink = styled(Link)`
    text-decoration: none;
    color: white;
    white-space: nowrap;
    box-sizing: border-box;
`;

export const UnstyledAnchor = styled.a`
    text-decoration: none;
    color: white;
    white-space: nowrap;
    box-sizing: border-box;
`;


export const CloseButton = styled(Close)`
  color: white;
    width: 32px;
    height: 32px;
    transition: color ${ global.transitions.MAIN_TRANSITION };
    :hover{
        color: #1b1b1b;
        cursor: pointer;
    }
`;

export const PageDim = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  z-index: 1;
`;

export { default as Card } from './component.card';
export { default as ImageRow } from './component.image.row';
