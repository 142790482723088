const transitions = {
    MAIN_TRANSITION: ".2s ease-in-out"
};

const colors = {
    PRIMARY_RGB: '43, 43, 43',
    PRIMARY_RGB_LIGHT: '65, 65, 65',
    PRIMARY_RGB_LIGHTER: '121, 121, 121'
};

const styles = {
    BORDER_RADIUS: "15px"
};

const sizes = {
    MOBILE_SMALL: '320px',
    MOBILE_MEDIUM: '375px',
    MOBILE_LARGE: '425px',
    TABLET: '768px',
    LAPTOP_SMALL: '1024px',
    LAPTOP_LARGE: '1440px',
    DESKTOP: '2560px'
};

export const IconStyle = `
    color: white;
    width: 54px;
    height: 54px;
    margin: 8px;
    transition: color ${ transitions.MAIN_TRANSITION };
    :hover{
        color: #1b1b1b;
        cursor: pointer;
    }
`;


export default { transitions, colors, styles, sizes };
