import React from "react";
import styled from "styled-components/macro";
import global from '../static'
import { Github, LinkedinSquare, Discord } from 'styled-icons/boxicons-logos'
import { UnstyledAnchor, UnstyledButton } from "../components";
import { IconStyle } from "../static";




const GitHubIcon = styled(Github)`
  ${ IconStyle }
`;

const DiscordIcon = styled(Discord)`
  ${ IconStyle }
`;

const LinkedInIcon = styled(LinkedinSquare)`
  ${ IconStyle }
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  background: rgb(${ global.colors.PRIMARY_RGB });
  width: 100%;
  height: min-content;
  padding: .5rem;
`;

const ContactButton = styled(UnstyledButton)`
  box-sizing: border-box;
  padding: 12px;
  color: white;
  min-height: 100%;
  border-radius: ${ global.styles.BORDER_RADIUS };
  font-size: 1em;
  border: 1px solid white;
  margin: 8px;
  :hover{
        background: white;
        color: #2b2b2b;
    }
`;


const FlexContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${ props => props.right ? 'flex-end' : 'flex-start' };
`;

export default ({ toggleDiscordModal, toggleContactModal }) => {

    return (
        <NavBar>
            <FlexContainer>
                <UnstyledAnchor href={ "https://github.com/dbgregory/react-sandbox" } target="_blank">
                    <GitHubIcon/>
                </UnstyledAnchor>
                <UnstyledAnchor href={ "https://www.linkedin.com/in/denver-gregory/" } target="_blank">
                    <LinkedInIcon/>
                </UnstyledAnchor>
                <DiscordIcon onClick={ () => toggleDiscordModal() }/>
            </FlexContainer>
            <FlexContainer right>
                <ContactButton onClick={ () => toggleContactModal() }>Contact</ContactButton>
            </FlexContainer>
        </NavBar>
    );
}
