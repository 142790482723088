import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// Redux imports
import configureStore from "./store";
import { Provider } from 'react-redux';




const store = configureStore();


ReactDOM.render(
    <Provider store={ store }>
        <App/>
    </Provider>
    , document.getElementById( 'root' )
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
